<template>
  <div>
    <page-title
      :heading="$t('erp.lang_voucher')"
      :subheading="$t('erp.lang_voucher')"
      :icon="icon"
      show-previous-button
      url-previous="/erp/baseData/voucher"
    ></page-title>
    <div class="app-main__inner">
      <voucher-details-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import VoucherDetailsComponent from "../../../../components/erp/baseData/voucher/VoucherDetailsComponent";

export default {
  components: {
    PageTitle,
    VoucherDetailsComponent,
  },
  props: ["id"],
  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
