<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="rounded-t">
      <v-tab key="info">
        {{ $t("erp.lang_voucher") }}
      </v-tab>
      <v-tab key="activity">
        {{ $t("erp.lang_voucherActivity") }}
      </v-tab>

      <v-tabs-items touchless v-model="tab">
        <v-tab-item key="info" class="mt-5">
          <v-layout>
            <v-flex md12>
              <div>
                <v-layout>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="voucherBalance"
                      :label="$t('erp.lang_VoucherValue')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="issuingDate"
                      :label="$t('erp.lang_voucherIssuerID')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4 mr-2 ml-2>
                    <v-text-field
                      outlined
                      @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      v-model="lastUse"
                      :label="$t('generic.lang_lastchange')"
                      autocomplete="off"
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex class="text-right">
                    <v-btn text color="error" @click="goBack">
                      {{ $t("generic.lang_prev") }}
                    </v-btn>
                    <v-btn
                      v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                      color="success"
                      :disabled="loading"
                      :loading="loading"
                      @click="reprintVoucherPDF()"
                    >
                      {{ $t("erp.lang_reprintVoucherPDF") }}
                    </v-btn>
                    <v-btn
                      v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                      color="success"
                      :disabled="loading"
                      :loading="loading"
                      @click="reprintVoucher(voucherBalance)"
                    >
                      {{ $t("erp.lang_reprintVoucher") }}
                    </v-btn>
                    <v-btn
                      color="warning"
                      :disabled="loading"
                      :loading="loading"
                      @click="voidVoucher()"
                    >
                      {{ $t("erp.lang_voidVoucher") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-tab-item>
        <v-tab-item key="activity">
          <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHERACTIVITY"
            :data="params"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            excel-file-name="voucher invoice"
            @displayEntry="displayEntry"
            show-display-buttons
          >
            <template v-slot:item.total="{ item }">
              {{ item.total | currency }}
            </template>
          </Datatable>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { Events } from "@/plugins/events";
import { ENDPOINTS } from "@/config";
import mixin from "../../../../mixins/KeyboardMixIns";
import Datatable from "../../../datatable/Datatable";
import {createVoucherPrintingData} from "../../../../plugins/printing/printVoucher";
import FileSaver from 'file-saver';
import {printDataFromPrinter} from "../../../../plugins/printing/printerController";
import {mapState} from "vuex";

export default {
  name: "VoucherDetailsComponent",

  components: {
    Datatable,
  },

  mixins: [mixin],

  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      tab: 0,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      errorDialogMessage: ""
    };
  },
  computed: {
    ...mapState([
      'api'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    params() {
      return {
        voucherUUID: `V$${this.$route.params.id}`,
      };
    },
    datatableHeaders() {
      return [
        {
          text: this.$t("generic.lang_id"),
          align: "left",
          value: "id",
          hide: true,
        },
        { text: this.$t("generic.lang_id"), value: "ident", sort: "desc" },
        {
          text: this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          value: "date",
        },
        { text: this.$t("generic.lang_seller_id"), value: "sellerid" },
        { text: this.$t("generic.lang_customer"), value: "cust" },
        { text: this.$t("erp.lang_totalsolditems"), value: "saleItems" },
        { text: this.$t("erp.lang_ware_total"), value: "total" },
        { text: this.$t("accounting.lang_paymenthod"), value: "paymentType" },
        {
          text: this.$t("generic.lang_TableNo"),
          value: "tableNo",
          hide: false,
        },
        { text: this.$t("generic.lang_parMaison"), value: "parMaison" },
        { text: this.$t("generic.lang_cashierID"), value: "cashierID" },
        { text: this.$t("generic.lang_receipttype"), value: "type" },
      ];
    },
    excelColumns() {
      return [
        { label: this.$t("generic.lang_id"), field: "ident" },
        {
          label: this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          field: "date",
        },
        { label: this.$t("generic.lang_seller_id"), field: "sellerid" },
        { label: this.$t("generic.lang_customer"), field: "cust" },
        { label: this.$t("erp.lang_totalsolditems"), field: "saleItems" },
        { label: this.$t("erp.lang_ware_total"), field: "total" },
        { label: this.$t("accounting.lang_paymenthod"), field: "paymentType" },
        { label: this.$t("generic.lang_TableNo"), field: "tableNo" },
        { label: this.$t("generic.lang_parMaison"), field: "parMaison" },
        { label: this.$t("generic.lang_cashierID"), field: "cashierID" },
        { label: this.$t("generic.lang_receipttype"), field: "type" },
      ];
    },
  },
  methods: {
    goBack() {
      this.$router.push("/erp/baseData/Voucher");
    },
    displayEntry(entry) {
      this.$router.push("/accounting/DisplayInvoice/" + entry.id);
    },
    getVoucher() {
      let id = this.$route.params.id;

      this.axios
        .post(ENDPOINTS.ERP.VOUCHER.GET, {
          voucherUUID: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.voucherBalance =
              res.data.formfillData.textFields.voucherBalance;
            this.issuingDate = res.data.formfillData.textFields.issuingDate;
            this.lastUse = res.data.formfillData.textFields.lastUse;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
    reprintVoucher(balance = this.voucherBalance) {
      let id = this.$route.params.id;

      // CHECK IF PRINTER EXIST
      if (!this.invoicePrinter) {
        Events.$emit("showSnackbar", {
          message: this.$t("erp.lang_noPrinterSelected"),
          color: "warning",
        });
        return;
      }
      let printVouchersData = createVoucherPrintingData("V$" + id, balance);

      this.loading = true;

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter, printVouchersData)
        .then(() => {})
        .catch((err) => {
          this.errorDialogMessage = this.$t("erp.lang_voucherCouldNotBeReprinted");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reprintVoucherPDF() {
      let id = this.$route.params.id;
      this.loading = true;

      this.axios
        .post(
          ENDPOINTS.ERP.VOUCHER.GETPDF,
          {
            voucherId: id,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          this.loading = false;

          if (res.status === 200) {
            FileSaver.saveAs(
              new Blob([res.data], {
                type: "application/pdf",
              }),
              "Gutschein_" + id + ".pdf"
            );

            this.loading = false;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.errorDialogMessage = this.$t("erp.lang_voucherCouldNotBeReprinted");
          this.loading = false;
        });
    },
    voidVoucher() {
      let uuid = this.$route.params.id;

      this.$swal({
        title: this.$t("generic.lang_voidVoucherTitleMSG"),
        text: this.$t("generic.lang_voidVoucherTextMSG"),
        icon: "warning",
        cancelButtonText: this.$t("generic.lang_cancel"),
        confirmButtonText: this.$t("generic.lang_delete"),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;

          this.axios
            .post(ENDPOINTS.ERP.VOUCHER.VOID, {
              uuid: uuid,
            })
            .then((res) => {
              if (res.data.status === true) {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_success"),
                  color: "success",
                });

                this.goBack();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            })
            .catch(() => {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
  mounted() {
    this.getVoucher();
  },
};
</script>
